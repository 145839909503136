<template>
    <multi-language-tabs
        class="chapter bg-white border rounded overflow-hidden"
        :class="{collapsed}"
        :include-locales="['nl']"
    >
        <template #default="{locale}">
            <!-- Chapter Title (`z-index: 1` so Chapter Details will slide down beneath this)-->
            <div
                class="position-relative d-flex mt-2 py-4"
                :data-locale="locale"
                style="z-index: 1;"
            >
                <div class="drag-handle d-flex align-items-center cursor-pointer px-3">
                    <fa icon="grip-lines" />
                </div>

                <b-form-group
                    class="flex-grow-1 mb-0"
                    :invalid-feedback="chapter.getErrors('title', locale)"
                    :state="!chapter.getErrors('title', locale)"
                >
                    <b-input
                        v-model="chapter.title[locale]"
                        :placeholder="$t('programs.edit.chapters.typeChapterTitle')"
                        class="font-weight-bold pl-0"
                        size="lg"
                    />
                </b-form-group>

                <div
                    class="d-flex align-items-center cursor-pointer px-3 mr-5"
                    @click="collapsed = !collapsed"
                >
                    <fa
                        icon="long-arrow-alt-down"
                        class="collapse-icon"
                    />
                </div>
            </div>

            <transition name="fade-down-up">
                <div v-show="!collapsed">
                    <!-- Chapter Description -->
                    <div class="px-5 pb-4">
                        <b-form-group
                            :label="$t('programs.model.chapters.body.label')"
                            :invalid-feedback="chapter.getErrors('body', locale)"
                            :state="!chapter.getErrors('body', locale)"
                        >
                            <b-textarea
                                v-model="chapter.body[locale]"
                                :placeholder="$t('programs.model.chapters.body.label')"
                                rows="7"
                            />
                        </b-form-group>

                        <div class="d-flex justify-content-end">
                            <b-button
                                variant="light"
                                class="text-danger"
                                @click="$emit('remove-chapter', chapter)"
                            >
                                <fa :icon="['fac', 'trash']" />
                            </b-button>
                        </div>
                    </div>

                    <!-- Chapter Medias -->
                    <div class="media flex-column">
                        <draggable
                            v-model="chapter.posts.models"
                            class="w-100"
                            handle=".drag-handle"
                        >
                            <program-chapter-media
                                v-for="post of chapter.posts.models"
                                :key="post.id"
                                :post="post"
                                :locale="locale"
                                class="border-bottom"
                                @remove-media="chapter.posts.remove(post)"
                            />
                        </draggable>

                        <!-- Add Media Button -->
                        <div class="media-item w-100 d-flex flex-row rounded p-4">
                            <!-- Invisible drag icon to take up exactly as much space as a media item -->
                            <div class="d-flex flex-column justify-content-center pr-3 text-secondary invisible">
                                <fa icon="grip-lines" />
                            </div>

                            <div
                                class="add-media-button d-flex justify-content-center rounded bg-light"
                                @click="$emit('chapter-add-media', chapter)"
                            >
                                <fa
                                    icon="plus"
                                    class="align-self-center"
                                />
                            </div>

                            <div class="d-flex pl-3 pr-3">
                                <span class="align-self-center">
                                    {{ $t('common.actions.addMedia') }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </template>
    </multi-language-tabs>
</template>

<script>
import MultiLanguageTabs from '@/components/common/MultiLanguageTabs';
import Draggable from 'vuedraggable';
import {Chapter} from '@/models/Chapter';
import ProgramChapterMedia from '@/components/programs/chapters/Media';

export default {
    name: 'ProgramChapter',
    components: {MultiLanguageTabs, ProgramChapterMedia, Draggable},
    props: {
        /**
         * The chapter to display.
         */
        chapter: {
            type: Chapter,
            required: true,
        },
        /**
         * Initial collapsed state.
         */
        initialCollapsed: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            collapsed: this.initialCollapsed,
        };
    },
};
</script>

<style lang="scss">
.chapter {
    .collapse-icon {
        transition: transform .3s;
        transform: rotate(180deg);
    }

    &.collapsed {
        .collapse-icon {
            transform: rotate(0deg);
        }
    }

    .media {
        background: #eaedf1;
    }

    .media-item:first-of-type {
        // stylelint-disable declaration-no-important
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .add-media-button {
        width: 128px;
        height: 72px;
        cursor: pointer;
        background: #f3f4f9;
    }
}
</style>
