<template>
    <div>
        <!-- Undo & Save Buttons -->
        <portal
            v-if="activated"
            to="program-edit-actions"
        >
            <b-btn
                variant="light"
                class="btn-min-width mr-3"
                :disabled="!chapters.changed()"
                @click="chapters.reset()"
            >
                {{ $t('common.actions.undoChanges') }}
            </b-btn>

            <!--
            Currently Collection can't detect if the order of chapters has changed.
            Thus we never disable the save button for now.
            TODO: Make Collection able to detect if the order has changed.
            -->
            <btn-resource
                variant="primary"
                class="btn-min-width"
                :resource="chapters"
                :disabled="false"
                @click="saveChapters()"
            >
                {{ $t('common.actions.saveChanges') }}
            </btn-resource>
        </portal>

        <!-- Draggable Chapters -->
        <b-form @submit.prevent="saveChapters()">
            <input
                type="submit"
                class="d-none"
            >

            <draggable
                v-model="chapters.models"
                class="w-100"
                handle=".drag-handle"
            >
                <program-chapter
                    v-for="chapter of chapters.models"
                    :key="chapter.id"
                    class="mb-3"
                    :chapter="chapter"
                    :initial-collapsed="false"
                    @remove-chapter="chapters.remove($event)"
                    @chapter-add-media="openMediaModal($event)"
                />
            </draggable>
        </b-form>
        <!-- Add Chapter Box -->
        <div
            class="d-flex bg-white cursor-pointer rounded overflow-hidden p-4"
            @click="addChapter()"
        >
            <btn-square variant="dark">
                <fa icon="plus" />
            </btn-square>

            <span class="ml-4 align-self-center">
                {{ $t('programs.edit.chapters.addChapter') }}
            </span>
        </div>

        <!-- Media Modal -->
        <media-modal
            id="media-modal"
            :initial-selection="currentMediaSelection"
            @add-media="currentChapter.posts.replace($event)"
        />
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import {Program} from '@/models/Program';
import {Chapter} from '@/models/Chapter';
import {tryCatchWithToast} from '@/library/helpers';
import MediaModal from '@/components/media/MediaModal';
import KeptAlive from '@/components/common/mixins/KeptAlive';
import ProgramChapter from '@/components/programs/chapters/Chapter';
import TryCatchWithToast from '@/components/common/mixins/TryCatchWithToast';
import ConfirmLeaveUnsaved from '@/components/common/mixins/ConfirmLeaveUnsaved';

export default {
    name: 'ProgramEditChapters',
    components: {ProgramChapter, Draggable, MediaModal},
    mixins: [ConfirmLeaveUnsaved, KeptAlive, TryCatchWithToast],
    props: {
        program: {
            type: Program,
            required: true,
        },
    },
    data: function() {
        return {
            chapters: this.program.chapters.clone(),
            currentChapter: null,
        };
    },
    computed: {
        currentMediaSelection() {
            return this.currentChapter ? this.currentChapter.posts.models : [];
        },
        hasUnsavedChanges() {
            return !!this.chapters.changed();
        },
    },
    created() {
        if (this.chapters.isEmpty()) this.addChapter();
    },
    methods: {
        addChapter() {
            this.chapters.add(new Chapter());
        },
        openMediaModal(chapter) {
            // Save the chapter that triggers opening the media modal,
            // so we can add selected media to that specific chapter.
            this.currentChapter = chapter;

            this.$bvModal.show('media-modal');
        },
        saveChapters() {
            this.tryCatchWithToast(async() => {
                await this.chapters.save();

                this.program.set('chapters', this.chapters);

                this.program.sync('chapters');
            }, {
                success: this.$t('programs.messages.saveChaptersSuccess'),
            });
        },
    },
};
</script>
