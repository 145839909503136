<template>
    <div class="media-item w-100 d-flex flex-row rounded p-4">
        <div class="d-flex flex-column justify-content-center pr-3 text-secondary drag-handle">
            <fa icon="grip-lines" />
        </div>

        <div class="d-flex flex-column justify-content-center">
            <ow-image
                class="rounded overflow-hidden d-flex"
                :image="post.thumbnail"
                src-key="200"
            />
        </div>

        <div class="d-flex flex-grow-1 flex-column justify-content-center pl-4 pr-3">
            <h6 class="mb-2">
                {{ post.title[locale] }}
            </h6>

            <!-- Show only first 3 lines of the post's description. -->
            <small
                class="text-secondary overflow-hidden"
                style="max-height: 3.3rem;"
            >
                {{ post.body[locale] }}
            </small>
        </div>

        <div class="d-flex flex-column justify-content-center">
            <b-button
                variant="light"
                size="sm"
                class="text-danger"
                @click="$emit('remove-media')"
            >
                <fa :icon="['fac', 'trash']" />
            </b-button>
        </div>
    </div>
</template>

<script>
import {Post} from '@/models/Post';
import Image from '@/library/Image';
import OwImage from '@/components/common/OwImage';

export default {
    name: 'ProgramChapterMedia',
    components: {OwImage},
    props: {
        locale: {
            type: String,
            required: true,
        },
        /**
         * The media to display.
         */
        post: {
            type: Post,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.media-item {
    background: rgba($white, 0);
    transition: background-color .3s;
    .btn {
        opacity: 0;
        transition: opacity .3s;
    }
    &:hover {
        background: $white;
        .btn {
            opacity: 1;
        }
    }

    .ow-image {
        background: $gray-100;
        > img {
            width: inherit;
            height: inherit;
            border: none;
            object-fit: cover;
        }
    }
}
</style>
